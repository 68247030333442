
import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/seo"
const blogPostTemplate = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPost.title} description="testing" />
    <h1>{data.wordpressPost.title}</h1>
    <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }} />
  </Layout>
)
export default blogPostTemplate;
export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
    }
  }
`